import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import "../style/gallery.css"
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-pager.css";
import "lightgallery/css/lg-autoplay.css";
import lightGallery from "lightgallery";
import ga1 from "../Asset/gallery/ga1.jpg"
import ga2 from "../Asset/gallery/ga2.jpg"
import ga3 from "../Asset/gallery/ga3.jpg"
import ga4 from "../Asset/gallery/ga4.jpg"
import ga5 from "../Asset/gallery/ga5.jpg"
import ga6 from "../Asset/gallery/ga6.jpg"
const Gallery = () => {
  useEffect(() => {
    // Initialize lightGallery
    lightGallery(document.getElementById("image-gallery"), {
      mode: "lg-fade",
      thumbnail: true,
      zoom: false,
      autoplay: false,
    });
  }, []);
  return (
    <div>
   <section
  className="page-title"
  style={{ backgroundImage: "url(https://img.freepik.com/free-photo/engineer-working-with-airplane_1098-12545.jpg?size=626&ext=jpg&ga=GA1.1.1412446893.1705104000&semt=ais)" }}
>
  <div className="auto-container">
    <div className="inner-container clearfix">
      <h1>News & Gallery</h1>
      <ul className="bread-crumb clearfix">
        <li>
          <a href="index.html">Home</a>
        </li>
        <li>News/Gallery</li>
      </ul>
    </div>
  </div>
</section>

      <div className="Gallery-Section-2" style={{paddingTop:"15px"}}>
  <div className="Gallery-Card">
  <div className="container">
          <div id="image-gallery" className="row">
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              href={ga1}
              data-lg-size="1600-2400"
              data-lg-srcset={ga1}
            >
              <img
                src={ga1}
                style={{ height: "300px", width: "100%" }}
                alt="Enquery"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={ga2}
              data-lg-size="1600-2400"
              data-lg-srcset={ga2}
            >
              <img
                src={ga2}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 1"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={ga3}
              data-lg-size="1600-2400"
              data-lg-srcset={ga3}
            >
              <img
                src={ga3}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={ga4}
              data-lg-size="1600-2400"
              data-lg-srcset={ga4}
            >
              <img
                src={ga4}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={ga5}
              data-lg-size="1600-2400"
              data-lg-srcset={ga5}
            >
              <img
                src={ga5}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={ga6}
              data-lg-size="1600-2400"
              data-lg-srcset={ga6}
            >
              <img
                src={ga6}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
          </div>
        </div>
  </div>
</div>

    </div>
  )
}

export default Gallery

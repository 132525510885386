import React, { useState } from "react";
import "../style/style.css";
import Servicedata from "./Servicedata";
import logo from "../Asset/reavitrwebsite/brandss/aaalogo.png"
import productjs from "./product";
import { RiHome4Line } from "react-icons/ri";
import { CiMail } from "react-icons/ci";
import { FaClockRotateLeft } from "react-icons/fa6";
import { RiArrowDropDownLine } from "react-icons/ri";
 function Hadder() {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  // Function to toggle the visibility of the dropdown
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  // State to track whether the dropdown is open or closed
    // State to track whether the dropdown is open or closed
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // Function to toggle the dropdown state
    const toggleDropdownnew = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
  return (
    <div>
      <header className="main-header">
        {/*Header Top*/}
        <div className="header-top">
          <div className="auto-container">
            <div className="inner-container clearfix">
              <div className="top-left">
                <ul className="clearfix">
                  <li>Welcome to Avitr Operations & Maintenance LLP Services </li>
                </ul>
              </div>
              <div className="top-right clearfix">
                <ul className="social-icon-one clearfix">
                  <li>
                    <a href="#">
                      <span className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="fab fa-google-plus-g" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="fab fa-pinterest" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="fab fa-dribbble" />
                    </a>
                  </li>
                </ul>
                <div className="call-btn">
                  <a href="/Contectus">Contact Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Header Top */}
        {/*Header-Upper*/}
        <div className="header-upper">
          <div className="auto-container">
            <div className="clearfix">
              <div className="pull-left logo-outer centerclasslogo">
                <div className="logo" style={{width:"135px"}}>
                  <a href="#">
                    <img src={logo} alt="" title="" />
                  </a>
                </div>
              </div>
              <div className="pull-right upper-right clearfix displaynone">
                {/*Info Box*/}
                <div className="upper-column info-box">
                  <div className="icon-box">
                    <span>
                  <RiHome4Line />
                  </span>
                  </div>
                  <ul>
                    <li>
                      <strong> KANTI ATHARVA, CENTRAL PARK,</strong>
                    </li>
                    <li>PALGHAR, MAHARASHTRA, INDIA</li>
                  </ul>
                </div>
                {/*Info Box*/}
                <div className="upper-column info-box">
                  <div className="icon-box">
                    <span>
                  <CiMail />
                  </span>
                  </div>
                  <ul>
                    <li>
                      <strong>Send your mail at</strong>
                    </li>
                    <li>
                      <a href="#">sales@aoml.org/calibration@aoml.org</a>
                    </li>
                  </ul>
                </div>
                {/*Info Box*/}
                <div className="upper-column info-box">
                  <div className="icon-box">
                    <span>
                    <FaClockRotateLeft />
                    </span>
                  </div>
                  <ul>
                    <li>
                      <strong>Working Hours</strong>
                    </li>
                    <li>Mon-Sat:9:30am to 5:30pm</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Header Upper*/}
        {/*Header Lower*/}
        <div className="header-lower">
          <div className="auto-container">
            <div className="nav-outer clearfix">
              {/* Main Menu */}
              <nav className="main-menu navbar-expand-md">
                <div className="navbar-header">
          
                  <button
  className="navbar-toggler"
  type="button"
  data-toggle="collapse"
  data-target="#navbarSupportedContent"
  aria-controls="navbarSupportedContent"
  aria-expanded="false"
  aria-label="Toggle navigation"
>
  <span className="icon-bar" />
  <span className="icon-bar" />
  <span className="icon-bar" />
</button>
                </div>
                <div
                  className="navbar-collapse collapse clearfix"
                  id="navbarSupportedContent"
                  
                >
                  <ul className="navigation clearfix">
                    <li className="current dropdown">
                      <a href="/">Home</a>

                     
                    </li>
                    <li className="dropdown">
                      <a href="/aboutus">About</a>

                      
                    </li>

                    <li className="dropdown">
        <a href="#" onClick={toggleDropdownnew}>Services</a>
        <ul style={{ display: isDropdownOpen ? "block" : "none" }}>
          {Servicedata.map((ele) => {
            const { service, slug } = ele;
            return (
              <li key={slug}>
                <a href={slug}>{service}</a>
              </li>
            );
          })}
        </ul>
        <div className="dropdown-btn" onClick={toggleDropdownnew}>
        <div className="fa">
          <RiArrowDropDownLine />
          </div>
        </div>
      </li>

                    <li className="dropdown">
                      <a href="/Gallery">News</a>
                    
                     
                    </li>
                    <li className="dropdown">
        <a href="#" onClick={toggleDropdown}>
          Aviation Certificate
        </a>
        <ul style={{ display: isDropdownVisible ? "block" : "none" }}>
          {productjs.map((value) => {
            const { ServiceName, productImg } = value;
            return (
              <li key={ServiceName}>
                <a href={productImg}>{ServiceName}</a>
              </li>
            );
          })}
        </ul>
        <div className="dropdown-btn" onClick={toggleDropdown}>
          <div className="fa">
          <RiArrowDropDownLine />
          </div>
        </div>
      </li>
                    <li>
                      <a href="/Contectus">Contact</a>
                    </li>
                  </ul>
                </div>
              </nav>
              {/* Main Menu End*/}
              {/*Search Box*/}
              <div className="search-box-outer">
                {/*Search Box*/}
                <div className="dropdown dropdown-outer">
                  
                  <ul
                    className="dropdown-menu pull-right search-panel"
                    aria-labelledby="dropdownMenu3"
                  >
                    <li className="panel-outer">
                      <div className="form-container">
                        <form method="post" action="blog.html">
                          <div className="form-group">
                            <input
                              type="search"
                              name="field-name"
                              defaultValue=""
                              placeholder="Search Here"
                              required=""
                            />
                            <button type="submit" className="search-btn">
                              <span className="fa fa-search" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/*End earch Box*/}
            </div>
          </div>
        </div>
        {/*End Header Lower*/}
        {/*Sticky Header*/}
        <div className="sticky-header">
          <div className="auto-container clearfix">
            {/*Logo*/}
            <div className="logo pull-left">
              <a href="index.html" className="img-responsive">
                <img src="images/logo-small.png" alt="" title="" />
              </a>
            </div>
            {/*Right Col*/}
            <div className="right-col pull-right">
              {/* Main Menu */}
              <nav className="main-menu  navbar-expand-md">
                <div className="navbar-header">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent1"
                    aria-controls="navbarSupportedContent1"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                <div
                  className="navbar-collapse collapse clearfix"
                  id="navbarSupportedContent1"
                >
                  <ul className="navigation clearfix">
                    <li className="current dropdown">
                      <a href="#">Home</a>
                      <ul>
                        <li>
                          <a href="index.html">Homepage One</a>
                        </li>
                        <li>
                          <a href="index-2.html">Homepage Two</a>
                        </li>
                        <li>
                          <a href="index-3.html">Homepage Three</a>
                        </li>
                        <li>
                          <a href="index-4.html">Homepage Four</a>
                        </li>
                        <li className="dropdown">
                          <a href="#">Header Styles</a>
                          <ul>
                            <li>
                              <a href="index.html">Header Style One</a>
                            </li>
                            <li>
                              <a href="index-2.html">Header Style Two</a>
                            </li>
                            <li>
                              <a href="index-3.html">Header Style Three</a>
                            </li>
                            <li>
                              <a href="index-4.html">Header Style Four</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <div className="dropdown-btn">
                        <span className="fa fa-angle-down" />
                      </div>
                    </li>
                    <li className="dropdown">
                      <a href="#">About</a>
                      <ul>
                        <li>
                          <a href="about.html">About</a>
                        </li>
                        <li>
                          <a href="appointment.html">Appointment</a>
                        </li>
                        <li>
                          <a href="team.html">Team</a>
                        </li>
                        <li>
                          <a href="price.html">Price</a>
                        </li>
                        <li>
                          <a href="coming-soon.html">Coming Soon</a>
                        </li>
                      </ul>
                      <div className="dropdown-btn">
                        <span className="fa fa-angle-down" />
                      </div>
                    </li>
                    <li className="dropdown">
                      <a href="#">Projects</a>
                      <ul>
                        <li>
                          <a href="project.html">Project</a>
                        </li>
                        <li>
                          <a href="project-detail.html">Project Detail</a>
                        </li>
                      </ul>
                      <div className="dropdown-btn">
                        <span className="fa fa-angle-down" />
                      </div>
                    </li>
                    <li className="dropdown">
                      <a href="#">Services</a>
                      <ul>
                        <li>
                          <a href="services.html">Services 01</a>
                        </li>
                        <li>
                          <a href="services-2.html">Services 02</a>
                        </li>
                        <li>
                          <a href="service-detail.html">Services Single</a>
                        </li>
                      </ul>
                      <div className="dropdown-btn">
                        <span className="fa fa-angle-down" />
                      </div>
                    </li>
                    <li className="dropdown has-mega-menu">
                      <a href="#">Pages</a>
                      <div className="mega-menu">
                        <div className="mega-menu-bar row clearfix">
                          <div className="column col-md-3 col-sm-3 col-xs-12">
                            <h3>About Us</h3>
                            <ul>
                              <li>
                                <a href="team.html">Team</a>
                              </li>
                              <li>
                                <a href="team-single.html">Team Single</a>
                              </li>
                              <li>
                                <a href="testimonials.html">Testimonial</a>
                              </li>
                              <li>
                                <a href="faq.html">FAQ's</a>
                              </li>
                              <li>
                                <a href="coming-soon.html">Coming Soon</a>
                              </li>
                            </ul>
                          </div>
                          <div className="column col-md-3 col-sm-3 col-xs-12">
                            <h3>Solutions</h3>
                            <ul>
                              <li>
                                <a href="service-detail.html">Solutions</a>
                              </li>
                              <li>
                                <a href="service-detail.html">
                                  Chemical Engineering
                                </a>
                              </li>
                              <li>
                                <a href="service-detail.html">
                                  Energy &amp; Power Engineering
                                </a>
                              </li>
                              <li>
                                <a href="service-detail.html">
                                  Oil &amp; Gas Engineering
                                </a>
                              </li>
                              <li>
                                <a href="service-detail.html">
                                  Civil Engineering
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="column col-md-3 col-sm-3 col-xs-12">
                            <h3>Blog</h3>
                            <ul>
                              <li>
                                <a href="blog.html">Our Blog</a>
                              </li>
                              <li>
                                <a href="blog-classic.html">Blog Classic</a>
                              </li>
                              <li>
                                <a href="blog-left-sidebar.html">
                                  Blog Left Sidebar
                                </a>
                              </li>
                              <li>
                                <a href="blog-right-sidebar.html">
                                  Blog Right Sidebar
                                </a>
                              </li>
                              <li>
                                <a href="blog-detail.html">Blog Details</a>
                              </li>
                            </ul>
                          </div>
                          <div className="column col-md-3 col-sm-3 col-xs-12">
                            <h3>Shop</h3>
                            <ul>
                              <li>
                                <a href="shop.html">Shop</a>
                              </li>
                              <li>
                                <a href="shop-single.html">Product Details</a>
                              </li>
                              <li>
                                <a href="shoping-cart.html">Cart Page</a>
                              </li>
                              <li>
                                <a href="checkout.html">Checkout Page</a>
                              </li>
                              <li>
                                <a href="login.html">Registration Page</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown-btn">
                        <span className="fa fa-angle-down" />
                      </div>
                    </li>
                    <li className="dropdown">
                      <a href="#">News</a>
                      <ul>
                        <li>
                          <a href="blog.html">News</a>
                        </li>
                        <li>
                          <a href="blog-detail.html">News Detail</a>
                        </li>
                        <li>
                          <a href="error-page.html">Error Page</a>
                        </li>
                      </ul>
                      <div className="dropdown-btn">
                        <span className="fa fa-angle-down" />
                      </div>
                    </li>
                    <li className="dropdown">
                      <a href="#">Shop</a>
                      <ul>
                        <li>
                          <a href="shop.html">Products</a>
                        </li>
                        <li>
                          <a href="shop-single.html">Products Detail</a>
                        </li>
                        <li>
                          <a href="checkout.html">Checkout</a>
                        </li>
                        <li>
                          <a href="cart.html">Shopping Cart</a>
                        </li>
                        <li>
                          <a href="login.html">Registration</a>
                        </li>
                      </ul>
                      <div className="dropdown-btn">
                        <span className="fa fa-angle-down" />
                      </div>
                    </li>
                    <li>
                      <a href="contact.html">Contact</a>
                    </li>
                  </ul>
                </div>
              </nav>
              {/* Main Menu End*/}
            </div>
          </div>
        </div>
        {/*End Sticky Header*/}
      </header>
    </div>
  );
}

export default Hadder;


import './App.css';
import Hadder from './components/Hadder';
import Home from "../src/pages/Home"
import Aboutus from './pages/Aboutus';
import Footer from './components/Footer';
import Contectus from './pages/Contectus';
import Service from './pages/Service';
import Servicedetil from './pages/servicedetil';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Gallery from './pages/Gallery';
import Servicedata from './components/Servicedata';
function App() {
  return (
  
  <>
          <BrowserRouter> 
      <Hadder/>
      <Routes>

     <Route path='/' element={<Home/>}></Route>
     <Route path='/aboutus' element={<Aboutus/>}></Route>
     <Route path='/Contectus' element={<Contectus/>}></Route>

     <Route path='/Gallery' element={<Gallery/>}></Route>
     <Route path='/Service' element={<Service/>}></Route>
     {
            Servicedata.map((route, index) => {
              console.log("route.slug",route.slug)
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<Servicedetil/>}
                  />
                </>
              )
            })
          }
      </Routes>
<Footer/>
    </BrowserRouter>
  </>
  );
}

export default App;

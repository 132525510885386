import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';


import imgws from "../Asset/reavitrwebsite/brandss/Aviator.gif"
// import required modules
import { Autoplay} from 'swiper/modules';

const Banner = () => {
  return (
    <div>
        <Swiper
          
        spaceBetween={30}
        loop={true}
        centeredSlides={true}
        freeMode={true}
       
        speed={800}
       
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
        <section className="main-slider">
  <div
    className="forcefullwidth_wrapper_tp_banner"
    id="rev_slider_one_forcefullwidth"
    style={{
      position: "relative",
      width: "100%",
      height: "auto",
      marginTop: 0,
      marginBottom: 0
    }}
  >
    <div
      className="rev_slider_wrapper fullwidthbanner-container tp-mouseover"
      id="rev_slider_one_wrapper"
      data-source="gallery"
      style={{
        marginTop: 0,
        marginBottom: 0,
        position: "absolute",
        overflow: "visible",
        height: 700,
        width:"100%",
        left: 0
      }}
    >
      <div
        className="rev_slider fullwidthabanner revslider-initialised tp-simpleresponsive rev_redraw_on_blurfocus"
        id="rev_slider_one"
        data-version="5.4.1"
        data-slideactive="rs-1688"
        style={{ maxHeight: 700, marginTop: 0, marginBottom: 0, height: 700 }}
      >
        <ul
          className="tp-revslider-mainul"
          style={{
            visibility: "visible",
            display: "block",
            overflow: "hidden",
            width: "100%",
            height: "100%",
            maxHeight: "none"
          }}
        >
          {/* Slide 1 */}
          <li
            data-description="Slide Description"
            data-easein="default"
            data-easeout="default"
            data-fsmasterspeed={1500}
            data-fsslotamount={7}
            data-fstransition="fade"
            data-hideafterloop={0}
            data-hideslideonmobile="off"
            data-index="rs-1687"
            data-masterspeed="default"
            data-param1=""
            data-param10=""
            data-param2=""
            data-param3=""
            data-param4=""
            data-param5=""
            data-param6=""
            data-param7=""
            data-param8=""
            data-param9=""
            data-rotate={0}
            data-saveperformance="off"
            data-slotamount="default"
            data-thumb="images/main-slider/image-3.jpg"
            data-title="Slide Title"
            data-transition="parallaxvertical"
            className="tp-revslider-slidesli"
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              zIndex: 18,
              visibility: "hidden",
              opacity: 0,
              backgroundColor: "rgba(255, 255, 255, 0)"
            }}
          >
            <div
              className="slotholder"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 0,
                width: "100%",
                height: "100%",
                backfaceVisibility: "hidden",
                transform: "translate3d(0px, -29px, 0px)",
                visibility: "inherit",
                opacity: 1
              }}
            >
              {/*Runtime Modification - Img tag is Still Available for SEO Goals in Source - <img alt="" class="rev-slidebg defaultimg" data-bgfit="cover" data-bgparallax="10" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src="images/main-slider/image-1.jpg">*/}
              <div className="tp-dottedoverlay yes" />
              <div
                className="tp-bgimg defaultimg "
                data-bgcolor="undefined"
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${imgws})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  visibility: "hidden",
                  zIndex: 20
                }}
                src="images/main-slider/image-1.jpg"
              />
            </div>
            <div
              className="tp-parallax-wrap"
              style={{
                position: "absolute",
                display: "block",
                visibility: "hidden",
                left: 135,
                top: 215,
                zIndex: 5
              }}
            >
              <div
                className="tp-loop-wrap"
                style={{ position: "absolute", display: "block" }}
              >
                <div
                  className="tp-mask-wrap"
                  style={{
                    position: "absolute",
                    display: "block",
                    overflow: "hidden",
                    transform: "matrix(1, 0, 0, 1, 0, 0)"
                  }}
                >
                  <div
                    className="tp-caption"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingtop="[0,0,0,0]"
                    data-responsive_offset="on"
                    data-type="text"
                    data-height="none"
                    data-width="['550','600','550','550']"
                    data-whitespace="normal"
                    data-hoffset="['15','15','15','15']"
                    data-voffset="['-120','-120','-120','-120']"
                    data-x="['left','left','left','left']"
                    data-y="['middle','middle','middle','middle']"
                    data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                    id="layer-127957542"
                    style={{
                      visibility: "inherit",
                      transition: "none 0s ease 0s",
                      textAlign: "inherit",
                      lineHeight: 27,
                      borderWidth: 0,
                      margin: 0,
                      padding: 0,
                      letterSpacing: 0,
                      fontWeight: 400,
                      fontSize: 15,
                      whiteSpace: "normal",
                      minHeight: 0,
                      minWidth: 550,
                      maxHeight: "none",
                      maxWidth: 550,
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 31, 0, 1)",
                      transformOrigin: "50% 50% 0px"
                    }}
                  >
                    <h4>Your Vehicle is</h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tp-parallax-wrap"
              style={{
                position: "absolute",
                display: "block",
                visibility: "hidden",
                left: 135,
                top: 260,
                zIndex: 5
              }}
            >
              <div
                className="tp-loop-wrap"
                style={{ position: "absolute", display: "block" }}
              >
                <div
                  className="tp-mask-wrap"
                  style={{
                    position: "absolute",
                    display: "block",
                    overflow: "hidden",
                    transform: "matrix(1, 0, 0, 1, 0, 0)"
                  }}
                >
                  <div
                    className="tp-caption"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingtop="[0,0,0,0]"
                    data-responsive_offset="on"
                    data-type="text"
                    data-height="none"
                    data-width="['700','700','700','700']"
                    data-whitespace="normal"
                    data-hoffset="['15','15','15','15']"
                    data-voffset="['-65','-65','-65','-65']"
                    data-x="['left','left','left','left']"
                    data-y="['middle','middle','middle','middle']"
                    data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                    id="layer-306929955"
                    style={{
                      visibility: "inherit",
                      transition: "none 0s ease 0s",
                      textAlign: "inherit",
                      lineHeight: 27,
                      borderWidth: 0,
                      margin: 0,
                      padding: 0,
                      letterSpacing: 0,
                      fontWeight: 400,
                      fontSize: 15,
                      whiteSpace: "normal",
                      minHeight: 0,
                      minWidth: 700,
                      maxHeight: "none",
                      maxWidth: 700,
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 52, 0, 1)",
                      transformOrigin: "50% 50% 0px"
                    }}
                  >
                    <h2>Save in our Hands</h2>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tp-parallax-wrap"
              style={{
                position: "absolute",
                display: "block",
                visibility: "hidden",
                left: 135,
                top: 325,
                zIndex: 5
              }}
            >
              <div
                className="tp-loop-wrap"
                style={{ position: "absolute", display: "block" }}
              >
                <div
                  className="tp-mask-wrap"
                  style={{
                    position: "absolute",
                    display: "block",
                    overflow: "hidden",
                    transform: "matrix(1, 0, 0, 1, 0, 0)"
                  }}
                >
                  <div
                    className="tp-caption"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingtop="[0,0,0,0]"
                    data-responsive_offset="on"
                    data-type="text"
                    data-height="none"
                    data-whitespace="normal"
                    data-width="['700','700','450','450']"
                    data-hoffset="['15','15','15','15']"
                    data-voffset="['5','5','5','5']"
                    data-x="['left','left','left','left']"
                    data-y="['middle','middle','middle','middle']"
                    data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                    id="layer-70781408"
                    style={{
                      visibility: "inherit",
                      transition: "none 0s ease 0s",
                      textAlign: "inherit",
                      lineHeight: 27,
                      borderWidth: 0,
                      margin: 0,
                      padding: 0,
                      letterSpacing: 0,
                      fontWeight: 400,
                      fontSize: 15,
                      whiteSpace: "normal",
                      minHeight: 0,
                      minWidth: 700,
                      maxHeight: "none",
                      maxWidth: 700,
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -62, 0, 1)",
                      transformOrigin: "50% 50% 0px"
                    }}
                  >
                    <div className="text">
                      We specialize in complete auto care at a low cost and in a{" "}
                      <br /> professional manner.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tp-parallax-wrap"
              style={{
                position: "absolute",
                display: "block",
                visibility: "hidden",
                left: 135,
                top: 430,
                zIndex: 5
              }}
            >
              <div
                className="tp-loop-wrap"
                style={{ position: "absolute", display: "block" }}
              >
                <div
                  className="tp-mask-wrap"
                  style={{
                    position: "absolute",
                    display: "block",
                    overflow: "hidden",
                    transform: "matrix(1, 0, 0, 1, 0, 0)"
                  }}
                >
                  <div
                    className="tp-caption tp-resizeme"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingtop="[0,0,0,0]"
                    data-responsive_offset="on"
                    data-type="text"
                    data-height="none"
                    data-width="auto"
                    data-whitespace="nowrap"
                    data-hoffset="['15','15','15','15']"
                    data-voffset="['105','105','105','105']"
                    data-x="['left','left','left','left']"
                    data-y="['middle','middle','middle','middle']"
                    data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                    id="layer-941346248"
                    style={{
                      visibility: "inherit",
                      transition: "none 0s ease 0s",
                      textAlign: "inherit",
                    
                      borderWidth: 0,
                      margin: 0,
                      padding: 0,
                      letterSpacing: 0,
                      fontWeight: 400,
                      fontSize: 15,
                      whiteSpace: "nowrap",
                      minHeight: 0,
                      minWidth: 0,
                      maxHeight: "none",
                      maxWidth: "none",
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 52, 0, 1)",
                      transformOrigin: "50% 50% 0px"
                    }}
                  >
                    <a
                      href="services.html"
                      className="theme-btn btn-style-one"
                      style={{
                        transition: "none 0s ease 0s",
                        textAlign: "inherit",
                       
                        borderWidth: 0,
                        margin: 0,
                        padding: "15px 40px",
                        letterSpacing: 0,
                        fontWeight: 700,
                        fontSize: 14
                      }}
                    >
                      Contact Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          {/* Slide 1 */}
          <li
            data-description="Slide Description"
            data-easein="default"
            data-easeout="default"
            data-fsmasterspeed={1500}
            data-fsslotamount={7}
            data-fstransition="fade"
            data-hideafterloop={0}
            data-hideslideonmobile="off"
            data-index="rs-1688"
            data-masterspeed="default"
            data-param1=""
            data-param10=""
            data-param2=""
            data-param3=""
            data-param4=""
            data-param5=""
            data-param6=""
            data-param7=""
            data-param8=""
            data-param9=""
            data-rotate={0}
            data-saveperformance="off"
            data-slotamount="default"
            data-thumb="images/main-slider/image-2.jpg"
            data-title="Slide Title"
            data-transition="parallaxvertical"
            className="tp-revslider-slidesli active-revslide"
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              zIndex: 20,
              visibility: "inherit",
              opacity: 1,
              backgroundColor: "rgba(255, 255, 255, 0)"
            }}
          >
            <div
              className="slotholder"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 0,
                width: "100%",
                height: "100%",
                backfaceVisibility: "hidden",
                transform: "translate3d(0px, -29px, 0px)",
                visibility: "inherit",
                opacity: 1
              }}
            >
              {/*Runtime Modification - Img tag is Still Available for SEO Goals in Source - <img alt="" class="rev-slidebg defaultimg" data-bgfit="cover" data-bgparallax="10" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src="images/main-slider/image-2.jpg">*/}
              <div className="tp-dottedoverlay yes" />
              <div
                className="tp-bgimg defaultimg "
                data-bgcolor="undefined"
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${imgws})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  width: "100%",
                  height: "100%",
                  opacity: 1,
                  visibility: "inherit",
                  zIndex: 20
                }}
                src="images/main-slider/image-2.jpg"
              />
            </div>
            <div
              className="tp-parallax-wrap"
              style={{
                position: "absolute",
                display: "block",
                visibility: "visible",
                left: 445,
                top: 215,
                zIndex: 5
              }}
            >
              <div
                className="tp-loop-wrap"
                style={{ position: "absolute", display: "block" }}
              >
                <div
                  className="tp-mask-wrap"
                  style={{
                    position: "absolute",
                    display: "block",
                    overflow: "hidden",
                    transform: "matrix(1, 0, 0, 1, 0, 0)"
                  }}
                >
                  <div
                    className="tp-caption"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingtop="[0,0,0,0]"
                    data-responsive_offset="on"
                    data-type="text"
                    data-height="none"
                    data-width="['550','600','550','550']"
                    data-whitespace="normal"
                    data-textalign="center"
                    data-hoffset="['0','0','0','0']"
                    data-voffset="['-120','-120','-120','-120']"
                    data-x="['center','center','center','center']"
                    data-y="['middle','middle','middle','middle']"
                    data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                    id="layer-611880837"
                    style={{
                      visibility: "inherit",
                      transition: "none 0s ease 0s",
                      textAlign: "center",
                      lineHeight: 27,
                      borderWidth: 0,
                      margin: 0,
                      padding: 0,
                      letterSpacing: 0,
                      fontWeight: 400,
                      fontSize: 15,
                      whiteSpace: "normal",
                      minHeight: 0,
                      minWidth: 550,
                      maxHeight: "none",
                      maxWidth: 550,
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                      transformOrigin: "50% 50% 0px"
                    }}
                  >
                    <h4>The Quality NDT and</h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tp-parallax-wrap"
              style={{
                position: "absolute",
                display: "block",
                visibility: "visible",
                left: 370,
                top: 260,
                zIndex: 5
              }}
            >
              <div
                className="tp-loop-wrap"
                style={{ position: "absolute", display: "block" }}
              >
                <div
                  className="tp-mask-wrap"
                  style={{
                    position: "absolute",
                    display: "block",
                    overflow: "hidden",
                    transform: "matrix(1, 0, 0, 1, 0, 0)"
                  }}
                >
                  <div
                    className="tp-caption"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingtop="[0,0,0,0]"
                    data-responsive_offset="on"
                    data-type="text"
                    data-height="none"
                    data-width="['700','700','700','700']"
                    data-textalign="center"
                    data-whitespace="normal"
                    data-hoffset="['0','0','0','0']"
                    data-voffset="['-65','-65','-65','-65']"
                    data-x="['center','center','center','center']"
                    data-y="['middle','middle','middle','middle']"
                    data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                    id="layer-194646998"
                    style={{
                      visibility: "inherit",
                      transition: "none 0s ease 0s",
                      textAlign: "center",
                      lineHeight: 27,
                      borderWidth: 0,
                      margin: 0,
                      padding: 0,
                      letterSpacing: 0,
                      fontWeight: 400,
                      fontSize: 15,
                      whiteSpace: "normal",
                      minHeight: 0,
                      minWidth: 700,
                      maxHeight: "none",
                      maxWidth: 700,
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                      transformOrigin: "50% 50% 0px"
                    }}
                  >
                    <h2>AVIATION MAINTENANCE

SERVICE</h2>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tp-parallax-wrap"
              style={{
                position: "absolute",
                display: "block",
                visibility: "visible",
                left: 370,
                top: 325,
                zIndex: 5
              }}
            >
              <div
                className="tp-loop-wrap"
                style={{ position: "absolute", display: "block" }}
              >
                <div
                  className="tp-mask-wrap"
                  style={{
                    position: "absolute",
                    display: "block",
                    overflow: "hidden",
                    transform: "matrix(1, 0, 0, 1, 0, 0)"
                  }}
                >
                  <div
                    className="tp-caption rs-toggle-content-active"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingtop="[0,0,0,0]"
                    data-responsive_offset="on"
                    data-type="text"
                    data-height="none"
                    data-textalign="center"
                    data-whitespace="normal"
                    data-width="['700','700','550','450']"
                    data-hoffset="['0','0','0','0']"
                    data-voffset="['5','5','5','15']"
                    data-x="['center','center','center','center']"
                    data-y="['middle','middle','middle','middle']"
                    data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                    id="layer-3950319"
                    style={{
                      visibility: "inherit",
                      transition: "none 0s ease 0s",
                      textAlign: "center",
                      lineHeight: 27,
                      borderWidth: 0,
                      margin: 0,
                      padding: 0,
                      letterSpacing: 0,
                      fontWeight: 400,
                      fontSize: 15,
                      whiteSpace: "normal",
                      minHeight: 0,
                      minWidth: 700,
                      maxHeight: "none",
                      maxWidth: 700,
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                      transformOrigin: "50% 50% 0px"
                    }}
                  >
                    <div className="text">
                    We specialize in NDT , Wheel And Brake &amp; Calibration Service in a
                    <br /> 
professional manner.
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tp-parallax-wrap"
              style={{
                position: "absolute",
                display: "block",
                visibility: "visible",
                left: 632,
                top: 430,
                zIndex: 5
              }}
            >
              <div
                className="tp-loop-wrap"
                style={{ position: "absolute", display: "block" }}
              >
                <div
                  className="tp-mask-wrap"
                  style={{
                    position: "absolute",
                    display: "block",
                    overflow: "hidden",
                    transform: "matrix(1, 0, 0, 1, 0, 0)"
                  }}
                >
                  <div
                    className="tp-caption tp-resizeme"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingtop="[0,0,0,0]"
                    data-responsive_offset="on"
                    data-type="text"
                    data-height="none"
                    data-width="auto"
                    data-whitespace="nowrap"
                    data-textalign="center"
                    data-hoffset="['0','0','0','0']"
                    data-voffset="['105','105','105','105']"
                    data-x="['center','center','center','center']"
                    data-y="['middle','middle','middle','middle']"
                    data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                    id="layer-502490364"
                    style={{
                      visibility: "inherit",
                      transition: "none 0s ease 0s",
                      textAlign: "center",
                  
                      borderWidth: 0,
                      margin: 0,
                      padding: 0,
                      letterSpacing: 0,
                      fontWeight: 400,
                      fontSize: 15,
                      whiteSpace: "nowrap",
                      minHeight: 0,
                      minWidth: 0,
                      maxHeight: "none",
                      maxWidth: "none",
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                      transformOrigin: "50% 50% 0px"
                    }}
                  >
                    <a
                      href="/Contectus"
                      className="theme-btn btn-style-one"
                      style={{
                        transition: "none 0s ease 0s",
                        textAlign: "inherit",
                       
                        borderWidth: 0,
                        margin: 0,
                        padding: "15px 40px",
                        letterSpacing: 0,
                        fontWeight: 700,
                        fontSize: 14
                      }}
                    >
                      Contact Now
                  
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          {/* Slide 1 */}
          <li
            data-description="Slide Description"
            data-easein="default"
            data-easeout="default"
            data-fsmasterspeed={1500}
            data-fsslotamount={7}
            data-fstransition="fade"
            data-hideafterloop={0}
            data-hideslideonmobile="off"
            data-index="rs-1698"
            data-masterspeed="default"
            data-param1=""
            data-param10=""
            data-param2=""
            data-param3=""
            data-param4=""
            data-param5=""
            data-param6=""
            data-param7=""
            data-param8=""
            data-param9=""
            data-rotate={0}
            data-saveperformance="off"
            data-slotamount="default"
            data-thumb="images/main-slider/image-2.jpg"
            data-title="Slide Title"
            data-transition="parallaxvertical"
            className="tp-revslider-slidesli"
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              zIndex: 18,
              visibility: "hidden",
              opacity: 0,
              backgroundColor: "rgba(255, 255, 255, 0)"
            }}
          >
            <div
              className="slotholder"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 0,
                width: "100%",
                height: "100%",
                backfaceVisibility: "hidden",
                transform: "translate3d(0px, -29px, 0px)",
                visibility: "inherit",
                opacity: 1
              }}
            >
              {/*Runtime Modification - Img tag is Still Available for SEO Goals in Source - <img alt="" class="rev-slidebg defaultimg" data-bgfit="cover" data-bgparallax="10" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src="images/main-slider/image-3.jpg">*/}
              <div className="tp-dottedoverlay yes" />
              <div
                className="tp-bgimg defaultimg "
                data-bgcolor="undefined"
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${imgws})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  visibility: "hidden",
                  zIndex: 20
                }}
                src="https://businesspost.ng/wp-content/uploads/2021/06/Aircraft-Maintenance-License.jpg"
              />
            </div>
            <div
              className="tp-parallax-wrap"
              style={{
                position: "absolute",
                display: "block",
                visibility: "hidden",
                left: 830,
                top: 215,
                zIndex: 5
              }}
            >
              <div
                className="tp-loop-wrap"
                style={{ position: "absolute", display: "block" }}
              >
                <div
                  className="tp-mask-wrap"
                  style={{
                    position: "absolute",
                    display: "block",
                    overflow: "hidden",
                    transform: "matrix(1, 0, 0, 1, 0, 0)"
                  }}
                >
                  <div
                    className="tp-caption"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingtop="[0,0,0,0]"
                    data-responsive_offset="on"
                    data-type="text"
                    data-height="none"
                    data-width="['490','490','490','490']"
                    data-whitespace="normal"
                    data-hoffset="['0','15','15','15']"
                    data-voffset="['-120','-120','-120','-120']"
                    data-x="['right','left','left','left']"
                    data-y="['middle','middle','middle','middle']"
                    data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                    id="layer-62263555"
                    style={{
                      visibility: "inherit",
                      transition: "none 0s ease 0s",
                      textAlign: "inherit",
                      lineHeight: 27,
                      borderWidth: 0,
                      margin: 0,
                      padding: 0,
                      letterSpacing: 0,
                      fontWeight: 400,
                      fontSize: 15,
                      whiteSpace: "normal",
                      minHeight: 0,
                      minWidth: 490,
                      maxHeight: "none",
                      maxWidth: 490,
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 31, 0, 1)",
                      transformOrigin: "50% 50% 0px"
                    }}
                  >
                    <h4>The Best Car Repair and</h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tp-parallax-wrap"
              style={{
                position: "absolute",
                display: "block",
                visibility: "hidden",
                left: 830,
                top: 260,
                zIndex: 5
              }}
            >
              <div
                className="tp-loop-wrap"
                style={{ position: "absolute", display: "block" }}
              >
                <div
                  className="tp-mask-wrap"
                  style={{
                    position: "absolute",
                    display: "block",
                    overflow: "hidden",
                    transform: "matrix(1, 0, 0, 1, 0, 0)"
                  }}
                >
                  <div
                    className="tp-caption"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingtop="[0,0,0,0]"
                    data-responsive_offset="on"
                    data-type="text"
                    data-height="none"
                    data-width="['490','590','490','490']"
                    data-whitespace="normal"
                    data-hoffset="['0','15','15','15']"
                    data-voffset="['-65','-65','-65','-65']"
                    data-x="['right','left','left','left']"
                    data-y="['middle','middle','middle','middle']"
                    data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                    id="layer-132853350"
                    style={{
                      visibility: "inherit",
                      transition: "none 0s ease 0s",
                      textAlign: "inherit",
                      lineHeight: 27,
                      borderWidth: 0,
                      margin: 0,
                      padding: 0,
                      letterSpacing: 0,
                      fontWeight: 400,
                      fontSize: 15,
                      whiteSpace: "normal",
                      minHeight: 0,
                      minWidth: 490,
                      maxHeight: "none",
                      maxWidth: 490,
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 52, 0, 1)",
                      transformOrigin: "50% 50% 0px"
                    }}
                  >
                    <h2>Maintenance Service</h2>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tp-parallax-wrap"
              style={{
                position: "absolute",
                display: "block",
                visibility: "hidden",
                left: 830,
                top: 325,
                zIndex: 5
              }}
            >
              <div
                className="tp-loop-wrap"
                style={{ position: "absolute", display: "block" }}
              >
                <div
                  className="tp-mask-wrap"
                  style={{
                    position: "absolute",
                    display: "block",
                    overflow: "hidden",
                    transform: "matrix(1, 0, 0, 1, 0, 0)"
                  }}
                >
                  <div
                    className="tp-caption rs-toggle-content-active"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingtop="[0,0,0,0]"
                    data-responsive_offset="on"
                    data-type="text"
                    data-height="none"
                    data-whitespace="normal"
                    data-width="['490','490','390','490']"
                    data-hoffset="['0','15','15','15']"
                    data-voffset="['5','5','5','5']"
                    data-x="['right','left','left','left']"
                    data-y="['middle','middle','middle','middle']"
                    data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                    id="layer-985996939"
                    style={{
                      visibility: "inherit",
                      transition: "none 0s ease 0s",
                      textAlign: "inherit",
                      lineHeight: 27,
                      borderWidth: 0,
                      margin: 0,
                      padding: 0,
                      letterSpacing: 0,
                      fontWeight: 400,
                      fontSize: 15,
                      whiteSpace: "normal",
                      minHeight: 0,
                      minWidth: 490,
                      maxHeight: "none",
                      maxWidth: 490,
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -62, 0, 1)",
                      transformOrigin: "50% 50% 0px"
                    }}
                  >
                    <div className="text">
                      We specialize in complete auto care at a low cost and in a{" "}
                      <br /> professional manner.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tp-parallax-wrap"
              style={{
                position: "absolute",
                display: "block",
                visibility: "hidden",
                left: 830,
                top: 430,
                zIndex: 5
              }}
            >
              <div
                className="tp-loop-wrap"
                style={{ position: "absolute", display: "block" }}
              >
                <div
                  className="tp-mask-wrap"
                  style={{
                    position: "absolute",
                    display: "block",
                    overflow: "hidden",
                    transform: "matrix(1, 0, 0, 1, 0, 0)"
                  }}
                >
                  <div
                    className="tp-caption tp-resizeme"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingtop="[0,0,0,0]"
                    data-responsive_offset="on"
                    data-type="text"
                    data-height="none"
                    data-width="['490','490','490','490']"
                    data-whitespace="nowrap"
                    data-hoffset="['0','15','15','15']"
                    data-voffset="['105','105','105','105']"
                    data-x="['right','left','left','left']"
                    data-y="['middle','middle','middle','middle']"
                    data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                    id="layer-33612471"
                    style={{
                      visibility: "inherit",
                      transition: "none 0s ease 0s",
                      textAlign: "inherit",
                 
                      borderWidth: 0,
                      margin: 0,
                      padding: 0,
                      letterSpacing: 0,
                      fontWeight: 400,
                      fontSize: 15,
                      whiteSpace: "nowrap",
                      minHeight: 0,
                      minWidth: 490,
                      maxHeight: "none",
                      maxWidth: 490,
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 52, 0, 1)",
                      transformOrigin: "50% 50% 0px"
                    }}
                  >
                    <a
                      href="services.html"
                      className="theme-btn btn-style-one"
                      style={{
                        transition: "none 0s ease 0s",
                        textAlign: "inherit",
                       
                        borderWidth: 0,
                        margin: 0,
                        padding: "15px 40px",
                        letterSpacing: 0,
                        fontWeight: 700,
                        fontSize: 14
                      }}
                    >
                                          Contact Now

                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div className="tp-loader off" style={{ display: "none" }}>
          <div className="dot1" />
          <div className="dot2" />
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
        <div
          className="tp-bannertimer"
          style={{
            visibility: "visible",
            width: "63.68%",
            transform: "translate3d(0px, 0px, 0px)"
          }}
        />
        <div
          className="tp-leftarrow tparrows hermes"
          style={{
            top: "50%",
            transform: "matrix(1, 0, 0, 1, 0, -55)",
            left: 0
          }}
        >
          <div className="tp-arr-allwrapper">
            <div
              className="tp-arr-imgholder"
              style={{
                backgroundImage: `url(${imgws})`,
              }}
            />
          </div>
        </div>
        <div
          className="tp-rightarrow tparrows hermes"
          style={{
            top: "50%",
            transform: "matrix(1, 0, 0, 1, -30, -55)",
            left: "100%"
          }}
        >
          <div className="tp-arr-allwrapper">
            <div
              className="tp-arr-imgholder"
              style={{
                backgroundImage: `url(${imgws})`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
    <div
      className="tp-fullwidth-forcer"
      style={{ width: "100%", height: 700 }}
    />
  </div>
</section>
        </SwiperSlide>
      
      </Swiper>




    </div>
  )
}

export default Banner

import dec1 from "../Asset/documet/document/AOML DGCA APPROVAL CERTIFICATE.pdf"
import dec2 from "../Asset/documet/document/AOML SMS DGCA APPROVAL.pdf"
import dec3 from "../Asset/documet/document/AVITR ISO 9001-2015 APPROVAL.pdf"
import dec4 from "../Asset/documet/document/Certificate of LLP Incorporation.pdf"
import dec5 from "../Asset/documet/document/IEC Certificate_240123_103602.pdf"
import dec6 from "../Asset/documet/document/AOML CAAN APPROVAL.pdf"

const productjs = [
  {
    "id": 1,
    "category": "Pharmaceuticals & APLS",
    "ServiceName": "AOML DGCA APPROVAL CERTIFICATE",

    "productImg": dec1
  },
  {
    "id": 2,
    "category": "Pharmaceuticals & APLS",
    "ServiceName": "AOML SMS DGCA APPROVAL",

    "productImg": dec2
  },
  {
    "id": 3,
    "category": "Pharmaceuticals & APLS",
    "ServiceName": "AVITR ISO 9001-2015 APPROVAL",

    "productImg": dec3},
    {
        "id": 4,
        "category": "Pharmaceuticals & APLS",
        "ServiceName": "Certificate of LLP Incorporation",
    
        "productImg": dec4},
        {
          "id": 5,
          "category": "Pharmaceuticals & APLS",
          "ServiceName": "IEC Certificate",
      
          "productImg": dec5},
          {
            "id": 5,
            "category": "Pharmaceuticals & APLS",
            "ServiceName": "AOML CAAN APPROVAL",
        
            "productImg": dec6},
        


];

export default productjs;

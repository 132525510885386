
import "../style/responsive.css"
import { GiPlaneWing } from "react-icons/gi";
import Banner from '../components/Banner'
import ab2 from "../Asset/ab2.png"
import { RiDoubleQuotesL } from "react-icons/ri";
// import { Collapse } from 'antd';
import { FaMinus } from "react-icons/fa6";
import logo from "../Asset/logo.png"
import { FiPlus } from "react-icons/fi";
import { GrUserWorker } from "react-icons/gr";
import { GiHelicopter } from "react-icons/gi";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { FaTools } from "react-icons/fa";
import servicedata from "../components/Servicedata"
import { BsTools } from "react-icons/bs";
import { GiBiplane } from "react-icons/gi";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { FaCarBattery } from "react-icons/fa6";
import "../style/theme.css"
import 'swiper/css';



import { LiaHelicopterSolid } from "react-icons/lia";
// import required modules
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';


import { Autoplay} from 'swiper/modules';

// import required modules
import { FreeMode, Pagination } from 'swiper/modules';
import a from "../Asset/reavitrwebsite/brands/a.webp"
import b from "../Asset/reavitrwebsite/brands/b.webp"
import c from "../Asset/reavitrwebsite/brands/c.webp"
import d from "../Asset/reavitrwebsite/brands/d.webp"
import e from "../Asset/reavitrwebsite/brands/e.webp"
import f from "../Asset/reavitrwebsite/brands/f.webp"
import g from "../Asset/reavitrwebsite/brands/g.webp"
import h from "../Asset/reavitrwebsite/brands/h.webp"
import i from "../Asset/reavitrwebsite/brands/i.webp"
import j from "../Asset/reavitrwebsite/brands/j.webp"
import k from "../Asset/reavitrwebsite/brands/k.webp"
import l from "../Asset/reavitrwebsite/brands/l.webp"
import m from "../Asset/reavitrwebsite/brands/m.webp"
import n from "../Asset/reavitrwebsite/brands/n.webp"
import o from "../Asset/reavitrwebsite/brands/o.webp"
import p from "../Asset/reavitrwebsite/brands/p.webp"
import q from "../Asset/reavitrwebsite/brands/q.webp"
import r from "../Asset/reavitrwebsite/brands/r.webp"
import s from "../Asset/reavitrwebsite/brands/s.webp"
import t from "../Asset/reavitrwebsite/brands/t.webp"
import u from "../Asset/reavitrwebsite/brands/u.webp"
import v from "../Asset/reavitrwebsite/brands/v.webp"
import w from "../Asset/reavitrwebsite/brands/w.webp"

const Home = () => {

  const [items, setItems] = useState([
    {
      title: "What Is Nondestructive Testing?",
      content: "Nondestructive testing is, simply stated, exactly what its name implies - testing without destroying - to investigate the material integrity of the test object. More specifically, a nondestructive test is an examination of an object, material or system in any manner, which will not impair its future usefulness. The purpose of the test may be to detect internal or external flaws, to measure geometric characteristics, to determine material structure or composition or to measure or detect some of the material&#39;s properties",
      content1:"Nondestructive tests in great variety are in worldwide use to detect variations in structure, minute changes in surface finish, the presence of cracks or other physical discontinuities, to measure the thickness of materials and coatings and to determine other characteristics of Aviation industrial products.",
      isActive: false
    },
    {
      title: "How far can eddy currents penetrate ?",
      content: "The frequency of current in the search coil ( f )",
      content3:"As frequency increases so the depth of penetration decreases. This is known as skin effect. Furthermore, the intensity of eddy currents decreases exponentially with depth. There is a specific depth beyond which the eddy current intensity is too small to be useful for detection of defects. This depth is known as the standard depth of penetration. It is defined as the depth below the surface at which the intensity of the eddy current field has been reduced to a value of 37% of its intensity at the surface.",
      content1:"The conductivity of the specimen ( σ )",
      content2:"The permeability of the specimen ( µ )",
      isActive: true
    },
 
    {
      title:"How is the quality of a radiograph assessed ?",
      content: "Density: The log of the ratio of the intensity of light incident on the film to the intensity of light transmitted through the film defines density:",
      content1:"Contrast: Degree of density difference between adjacent areas on a radiograph.",
      content2:"Sensitivity: Objective measurement of radiographic quality.",
      se1:"Density",
      se2:"Contrast",
      se3:"Definition",
      se4:"Sensitivity",
      isActive: false
    }
  ]);
  
  const handleItemClick = (index) => {
    const newItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, isActive: !item.isActive };
      } else {
        return { ...item, isActive: false };
      }
    });
    setItems(newItems);
  };

  return (
    <div>
        <Banner/>
        <section
  className="call-to-action"
  style={{ backgroundImage: "url(https://expert-themes.com/html/motor-expert/images/background/5.jpg)" }}
>
  <div className="inner-container clearfix">
    <div className="title-box">
      <h3>
       
        For all your <span>aircraft MAINTENANCE calibration & structural repairs </span> needs, we're here to assist you. 
      </h3>
    </div>
    <div className="btn-box">
      <a href="/Contectus" className="theme-btn btn-style-one">
        Make An Appointment
      </a>
    </div>
  </div>
</section>
<section className="services-section" style={{paddingBottom:"0px"}}>
  <div className="auto-container">
    <div className="services-carousel owl-carousel owl-theme owl-rtl owl-loaded owl-drag">
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      <div className="owl-stage-outer">
        <div
          className="owl-stage"
          style={{
           
          }}
        >
         <div className="row">
         {servicedata.map((ele) => {
                              const {service,slug,img1} = ele;
                              return (
            <div className="col-md-4">
            <div className="owl-item" style={{   }}>
            <div className="service-block">
              <div className="inner-box">
                <div className="image-box">
                  <figure>
                    <img src={img1} alt=""  style={{height:"380px"}}/>
                  </figure>
                  <div className="title">
                    <h4>{service}</h4>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="title-box">
                    <span className="icon flaticon-electrical" />
                    <h4>
                      <a href="#">{service}</a>
                    </h4>
                  </div>
                  <p>
                    We should point out that maintaining your vehicle with an
                    occasional visual inspectionfluid level.
                  </p>
                  <a href={slug} className="read-more">
                    Read More <i className="fa fa-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
            </div> );
                            })}
           
            {/* {choutelajson.map((ele) => {
                              const { title, slug,img } = ele;
                              return (
                                <div className="col-lg-4 col-md-4 mt-5">
                                <div
                                  className="featured-item services  text-center"
                                  data-bg-color="rgba(253,91,3,0.070)"
                                  style={{ backgroundColor: "rgba(253, 91, 3, 0.07)" }}
                                >
                                  <img src={img} width={325} height={200} />
                                  <div className="featured-title">
                                    <h5 style={{ textAlign: "center", marginTop: 10 }}>{title}</h5>
                                  </div>
                                  <div className="featured-desc">
                                    <a className="btn btn-2 gst" >
                                      {" "}
                                      <span className="btn-icon btn-arrow" />
                                     
                                      <p className="btn-text">  <Link to={slug}>Read More</Link></p>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              );
                            })} */}
         </div>
          
          
         
        </div>
      </div>
     
      
    </div>
  </div>
</section>

<section
  className="services-section-two" style={{paddingTop:"50px"}}
  // style={{ backgroundImage: "url(https://expert-themes.com/html/motor-expert/images/background/pattern-2.png)" }}
>
  <div className="auto-container">
    <div className="sec-title text-center">
      <h2>Our Esteemed Clients</h2>
      <div className="separator">
          <span className='icon'>
          <LiaHelicopterSolid />
          </span>
      </div>
    </div>
    <Swiper
        slidesPerView={6}
        spaceBetween={30}
        freeMode={true}
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
          640: {
              slidesPerView: 2,
              spaceBetween: 20,
          },
          768: {
              slidesPerView: 2,
              spaceBetween: 40,
          },
          1024: {
              slidesPerView: 6,
              spaceBetween: 50,
          },
      }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        modules={[FreeMode,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={a} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={b} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={c} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={d} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={e} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={f} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={g} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={h} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={i} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={j} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={k} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={l} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={m} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={n} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={o} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={p} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={q} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={r} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={s} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={t} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={u} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={v} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"150px"}}><img src={w} alt="" /></div></SwiperSlide>
      </Swiper>
  </div>
</section>

<section
  className="fun-fact-section"
  style={{ backgroundImage: "url(images/background/1.jpg)" }}
>
  <div className="auto-container">
    <div className="row clearfix">
      {/* Count box */}
      <div className="count-box col-lg-3 col-md-6 col-sm-12 counted">
        <div className="inner-box">
          <div className="icon-box">
          <GrUserWorker />
          </div>
          <span className="count-text" data-speed={2000} data-stop={1035}>
            1035
          </span>
          <div className="counter-title">
            <h5>Total experts</h5>
          </div>
        </div>
      </div>
      {/* Count box */}
      <div className="count-box col-lg-3 col-md-6 col-sm-12 counted">
        <div className="inner-box">
          <div className="icon-box">
          <GiHelicopter />
          </div>
          <span className="count-text" data-speed={2000} data-stop={1035}>
            1035
          </span>
          <div className="counter-title">
            <h5>Service Done</h5>
          </div>
        </div>
      </div>
      {/* Count box */}
      <div className="count-box col-lg-3 col-md-6 col-sm-12 counted">
        <div className="inner-box">
          <div className="icon-box">
          <HiOutlineEmojiHappy />

          </div>
          <span className="count-text" data-speed={2000} data-stop={1226}>
            1226
          </span>
          <div className="counter-title">
            <h5>Happy Client</h5>
          </div>
        </div>
      </div>
      {/* Count box */}
      <div className="count-box col-lg-3 col-md-6 col-sm-12 counted">
        <div className="inner-box">
          <div className="icon-box">
          <FaTools />
          </div>
          <span className="count-text" data-speed={2000} data-stop={1035}>
            1035
          </span>
          <div className="counter-title">
            <h5>Total Service</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="video-and-faqs">
  <div className="auto-container">
    <div className="row clearfix">
      {/* Video Column */}
      <div className="video-column col-lg-6 col-md-12 col-sm-12">
       
          
            <img style={{height:"100%"}} src="https://assets.verticalmag.com/wp-content/uploads/2018/08/166-16-103-1024x682.jpg" alt="" />
           
          
       
      </div>
      {/* Faq Column */}
      <div className="faq-column col-lg-6 col-md-12 col-sm-12">
        <div className="inner-column">
          <div className="sec-title ">
            <h2>Some Client FAQ’s</h2>
            <div className="separator">
              <span className='icon'> 
              <LiaHelicopterSolid />
              </span>
          
            </div>
          </div>
          {/*Accordion Box*/}
          <ul className="accordion-box">
      {items.map((item, index) => (
        <li key={index} className={`accordion block ${item.isActive ? 'active-block' : ''}`}>
          <div className={`acc-btn ${item.isActive ? 'active' : ''}`} onClick={() => handleItemClick(index)}>
          {item.isActive ? <FaMinus />: <FiPlus />}
            {item.title}
          </div>
          <div className={`acc-content ${item.isActive ? 'current' : ''}`}>
            <div className="content">
              <p>{item.content}</p>
              <br />
              <p>{item.content1}</p>
              <br />
              <p>{item.content2}</p>
              <br />
              <p>
              <p>{item.content3}</p>
              </p>
             
        
              <br />
              <div className="row">
                <div className="col-6">{item.se1}</div>
                <div className="col-6">{item.se2}</div>
                <div className="col-6">{item.se3}</div>
                <div className="col-6">{item.se4}</div>

              </div>
              
            </div>
          </div>
        </li>
      ))}
    </ul>
          {/*End Accordion Box*/}
        </div>
      </div>
    </div>
  </div>
</section>

<section
  className="testimonial-section"
  style={{ backgroundImage: "url(images/background/3.jpg)" }}
>
  <div className="auto-container">
    <div className="sec-title text-center">
      <h2>What Client Says</h2>
      <div className="separator">
      <LiaHelicopterSolid />
      </div>
    </div>
    {/* Testimonial Block */}
    <div className="testimonial-carousel owl-carousel owl-theme owl-rtl owl-loaded owl-drag">
      {/* Testimonial Block */}
      {/* Testimonial Block */}
      <div className="owl-stage-outer">
        <div
          className="owl-stage"
         
        >
            <Swiper
        slidesPerView={2}
        spaceBetween={30}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
          640: {
              slidesPerView: 1,
              spaceBetween: 20,
          },
          768: {
              slidesPerView: 2,
              spaceBetween: 40,
          },
          1024: {
              slidesPerView: 2,
              spaceBetween: 50,
          },
      }}
        className="mySwiper"
      >
        <SwiperSlide> <div
            className="owl-item cloned"
            style={{ }}
          >
            <div className="testimonial-block even">
              <figure className="thumb">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdO4RkUWOvow89fd88GEuaTIpPxIMGUZwB5A&usqp=CAU" alt="" />
              </figure>
              <p>
                “Exceptional Avitr Operations & Maintenance LLP services have transformed our aviation operations. Their expertise ensures peak performance, and their dedication is unparalleled. Highly recommended!”
              </p>
              <div className="name">
                <span className="designation">Samir</span>
              </div>
              <span className="icon " >
              <RiDoubleQuotesL />
                </span>
            </div>
          </div></SwiperSlide>
          <SwiperSlide> <div
            className="owl-item cloned"
            style={{ }}
          >
            <div className="testimonial-block even">
              <figure className="thumb">
                <img src="https://pbs.twimg.com/profile_images/1605157322476634112/2h4456px_400x400.jpg" alt="" />
              </figure>
              <p>
                “  Avitr Operations & Maintenance LLP services have been a game-changer for us. Their team's commitment to excellence and reliability sets them apart in the industry. A trusted partner indeed.”
              </p>
              <div className="name">
                 <span className="designation">Rahul</span>
              </div>
              <span className="icon " >
              <RiDoubleQuotesL />
                </span>
            </div>
          </div></SwiperSlide>
          <SwiperSlide> <div
            className="owl-item cloned"
            style={{  }}
          >
            <div className="testimonial-block even">
              <figure className="thumb">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfgsLmpkfgPk1CXQDM9EddxDLlG4vEy1LSlw&usqp=CAU" alt="" />
              </figure>
              <p>
                “Reliable, efficient, and professional Operations & Maintenance LLP services and maintenance have consistently exceeded our expectations in the aviation sector. Their expertise is crucial for our seamless operations. Impressive teamwork!”
              </p>
              <div className="name">
                 <span className="designation">Sumit</span>
              </div>
              <span className="icon " >
              <RiDoubleQuotesL />
                </span>
            </div>
          </div></SwiperSlide>
          <SwiperSlide> <div
            className="owl-item cloned"
            style={{ }}
          >
            <div className="testimonial-block even">
              <figure className="thumb">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLWltO_RzTpY_bKJ5glRBmGHEPbuUj3YnrVQ&usqp=CAU" alt="" />
              </figure>
              <p>
                “Avitr Operations & Maintenance LLP services has been instrumental in elevating our aviation operations through top-notch operation and maintenance services. Their commitment to safety, precision, and efficiency is commendable. A trustworthy partner for sustainable success.”
              </p>
              <div className="name">
                 <span className="designation">Lakhan</span>
              </div>
              <span className="icon " >
              <RiDoubleQuotesL />
                </span>
            </div>
          </div></SwiperSlide>
       
      </Swiper>
         
         
        
        </div>
      </div>
   
    </div>
  </div>
</section>


    </div>
  )
}

export default Home

import React from 'react'
import servicedata from "../components/Servicedata"
const Service = () => {
  return (
    <div>
      <section
  className="page-title"
  style={{ backgroundImage: "url(https://img.freepik.com/free-photo/engineer-working-with-airplane_1098-12545.jpg?size=626&ext=jpg&ga=GA1.1.1412446893.1705104000&semt=ais)" }}
>
  <div className="auto-container">
    <div className="inner-container clearfix">
      <h1>services </h1>
      <ul className="bread-crumb clearfix">
        <li>
          <a href="/">Home</a>
        </li>
        <li>services </li>
      </ul>
    </div>
  </div>
</section>
<section className="services-page-section">
  <div className="auto-container">
    <div className="row clearfix">
      {/* Service Block */}
      {servicedata.map((ele) => {
                              const {service,slug,img1} = ele;
                              return (
      <div className="service-block col-lg-4 col-md-6 col-sm-12">
        <div className="inner-box">
          <div className="image-box">
            <figure>
              <img src={img1} alt="" style={{height:"380px"}} />
            </figure>
            <div className="title">
              <h4>{service}</h4>
            </div>
          </div>
          <div className="caption-box">
            <div className="title-box">
              <span className="icon flaticon-electrical" />
              <h4>
                <a href="#">{service}</a>
              </h4>
            </div>
            <p>
              We should point out that maintaining your vehicle with an
              occasional visual inspectionfluid level.
            </p>
            <a href={slug} className="read-more">
              Read More <i className="fa fa-angle-double-right" />
            </a>
          </div>
        </div>
      </div>
     );
    })}
    </div>
    {/* Styled Pagination */}
  
  </div>
</section>

    </div>
  )
}

export default Service

import React from 'react'
import certfid from "../Asset/certifed.jpg"
import logo from "../Asset/reavitrwebsite/brandss/aalogo.png"
import isocet from "../Asset/isocet.png"
import fot1 from "../Asset/fot1.jpg"
import fot2 from "../Asset/fot2.jpg"
import FOOTERCERT from "../Asset/FOOTERCERT.jpg"
const Footer = () => {
  return (
    <div>
      <footer
  className="main-footer"
  style={{ backgroundImage: "url(images/background/4.jpg)" }}
>
  <div className="auto-container">
    {/*Widgets Section*/}
    <div className="widgets-section">
      <div className="row clearfix">
        {/*Footer Column*/}
        <div className="footer-column col-lg-3 col-md-6 col-sm-12">
          <div className="footer-widget about-widget">
            <div className="footer-logo">
              <figure>
                <a href="index.html">
                  <img src={logo} alt="" style={{width:"135px"}} />
                </a>
              </figure>
            </div>
            <div className="widget-content">
              <div className="text">
               
Avitr Operations & Maintenance LLP offers comprehensive services, ensuring optimal functionality and longevity. Our expert team excels in efficient operations and timely maintenance, delivering tailored solutions for sustainable performance across diverse industries.
              </div>
             
             
            </div>
          </div>
        </div>
        {/*Footer Column*/}
        <div className="footer-column col-lg-3 col-md-6 col-sm-12">
          <div className="footer-widget services-widget">
            <h2 className="widget-title">Links</h2>
            <div className="widget-content">
              <ul className="services-list">
                <li>
                <a href="/">Home</a>
                </li>
                <li>
                <a href="/aboutus">About</a>
                </li>
                <li>
                <a href="/Service">Services</a>
                </li>
                <li>
                <a href="/Gallery">News</a>
                </li>
                <li>
                      <a href="/Contectus">Contact</a>
                    </li>
                

              </ul>
            </div>
          </div>
        </div>
        {/*Footer Column*/}
        <div className="footer-column col-lg-3 col-md-6 col-sm-12">
          {/*Footer Column*/}
          <div className="footer-widget gallery-widget">
            <h2 className="widget-title"> Certified</h2>
            {/*Footer Column*/}
            <div className="widget-content">
              <div className="outer clearfix">
              <ul className="services-list">
                
                <li>
                <img src={FOOTERCERT} alt="" style={{borderRadius:"25px", paddingBottom:"10px"}}  />
                
                </li>
                
                

              </ul>
              </div>
            </div>
          </div>
        </div>
        {/*Footer Column*/}
        <div className="footer-column col-lg-3 col-md-6 col-sm-12">
          {/*Footer Column*/}
          <div className="footer-widget news-widget">
            <h2 className="widget-title">Location</h2>
            {/*Footer Column*/}
            <div style={{borderRadius:"25px"}}>
              <iframe style={{borderRadius:"25px"}}
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7518.639398340725!2d72.80164464126872!3d19.570799526952975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be701de6239250b%3A0xdacc35919b1b9da1!2sKanti%20Atharva!5e0!3m2!1sen!2sin!4v1706766093769!5m2!1sen!2sin"/>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*Footer Bottom*/}
  <div className="footer-bottom">
    <div className="auto-container">
      <div className="copyright-text">
        <p>
          Copyrights © 2024 All Rights Reserved. by{" "}
          <a href="#"> Avitr</a>
        </p>
      </div>
    </div>
  </div>
</footer>

    </div>
  )
}

export default Footer
